import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

function App() {
  const auth = getAuth();
  const ui = new firebaseui.auth.AuthUI(auth);
  ui.start("#firebaseui-auth-container", {
    signInOptions: [GoogleAuthProvider.PROVIDER_ID],
    // Other config options...
  });

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <div id="firebaseui-auth-container"></div>

        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
